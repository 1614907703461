<template>
  <div class="mask">
    <div class="nameFrom">
      <p class="topName">What's your name?</p>
      <van-cell-group inset class="textarea">
        <van-field
          v-model="nickname"
          label=""
          border="true"
          maxlength="20"
          placeholder="Enter you nickname"
          @update:model-value="changeNum"
          clearable
        />
      </van-cell-group>
      <div class="remarksName">
        <span>Nickname cannot exceed 20 characters</span>
        <span class="right">
          <span :style="{ color: number == 0 ? 'red' : '#999999' }">{{
            number
          }}</span
          >/20
        </span>
      </div>
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        class="okButton"
        @click="putName"
      >
        OK
      </van-button>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import { useRouter } from "vue-router";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";
import service from "@/utils/api";
import { Toast } from "vant";
import method from "../utils/codeJudgment";

export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      nickname: "",
      number: "0",
    });
    // 输入名称
    const changeNum = (val) => {
      state.number = val.length;
    };
    // 进入快乐星球
    const putName = () => {
      // 验证新用户注册姓名不能为空
      if (state.nickname.replace(/\s+/g, "") == "") {
        Toast.fail("User name cannot be empty");
        return false;
      }
      const params = {
        nickname: state.nickname,
      };
      service.getNickname(params).then((res) => {
        if (res.data.errcode == 0) {
          router.push({ path: "/loginSecond" });
        } else {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        }
      });
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      changeNum,
      putName,
    };
  },
};
</script>

<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
  .mask {
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/img/popup.png");
    background-size: 115% 100%;
    background-position-x: center;
    .nameFrom {
      width: 26%;
      height: 50%;
      margin: 0 auto;
      position: relative;
      top: 25%;
      .topName {
        width: 100%;
        height: 10%;
        margin: 0 auto;
        font-size: 16px;
        color: #ffffff;
      }
      // 输入框
      .textarea {
        width: 100%;
        color: black;
        height: 14%;
        background-color: transparent;
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        padding: 0 6px;
        .van-cell {
          padding: 0 2%;
          font-size: 10px;
        }
      }
      .van-cell-group--inset {
        margin: 7.6% 0 5% 0;
      }
      // 提示框
      .remarksName {
        font-size: 10px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        word-wrap: break-word;
        word-break: break-all;
        color: #999999;
        .right {
          width: 120px;
          text-align: right;
        }
      }
      // 按钮
      .okButton {
        width: 100%;
        height: 40px;
        background-color: transparent;
        background-image: url("../assets/img/account.png");
        background-size: 100% 105%;
        background-repeat: no-repeat;
        border: none;
        margin: 14px 0px;
        font-size: 16px;
      }
    }
  }
}
</style>
