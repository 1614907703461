<template>
  <div>
    <img
      src="../assets/img/goback1.png"
      alt=""
      @click="onClickLeft"
      class="goback"
    />
    <div class="loginBox">
      <div class="accountTitle">
        <img src="../assets/img/image.png" alt="" />
        <ul>
          <li class="fontTitle">快乐星球</li>
          <li class="englishTitle">HAPPY STAR</li>
        </ul>
      </div>
      <p class="tableTitle">Sing Up</p>

      <van-form @submit="onBlur">
        <van-cell-group inset class="cellTable">
          <van-cell value="Verification code" />
          <van-field
            v-model="resetFrom.code"
            type="code"
            label=""
            placeholder="code"
            clearable
            border="true"
            left-icon="shield-o"
            maxlength="20"
          />
        </van-cell-group>
        <p class="checktitle" @click="protocolPrivacy">
          <i class="iBox"
            >I don't have any message from Happy Star Support...</i
          >
        </p>
      </van-form>
      <van-button
        type="default"
        native-type="submit"
        class="getCode"
        @click="getEmail"
      >
        Get Verification code
      </van-button>
      <van-button size="small" type="primary" class="getCode countDown"
        >{{ numDown }} s</van-button
      >
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        class="loginButton"
        @click="getDone"
      >
        Done
      </van-button>
    </div>
    <!-- 遮罩层 -->
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <img
          src="../assets/img/close1.png"
          alt=""
          class="closeButton"
          @click="closeMask"
        />
        <giveName></giveName>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import "vant/lib/button/style";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import service from "../utils/api";
import giveName from "./giveName.vue";
import {
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
} from "@vue/runtime-core";
import method from "../utils/codeJudgment";

export default {
  name: "registration",
  props: {},
  components: {
    giveName,
  },
  setup() {
    const router = useRouter();
    const ctx = getCurrentInstance();
    const _this = ctx.appContext.config.globalProperties;
    const state = reactive({
      resetFrom: [],
      checked: "",
      // 倒计时
      // numState: "GET",
      numDown: "120",
      timer: null,
      show: false,
    });
    // 获取验证码
    const getEmail = () => {
      getCodeTime();
      // 调用接口，发送验证码
      service.getQCode({ account: _this.$route.query.account }).then((res) => {
        if (res.data.errcode !== 0) {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        }
      });
    };
    // 验证码倒计时
    const getCodeTime = () => {
      const TIME_COUNT = 120;
      if (!state.timer) {
        state.numDown = TIME_COUNT;
        state.timer = setInterval(() => {
          if (state.numDown > 1 && state.numDown <= TIME_COUNT) {
            state.numDown--;
          } else {
            clearInterval(state.timer);
            state.timer = null;
            state.numDown = TIME_COUNT;
          }
        }, 1000);
      }
    };
    // 提交
    const getDone = () => {
      const params = {
        account: _this.$route.query.account,
        code: state.resetFrom.code,
        password: _this.$route.query.password,
      };
      service.getRegist(params).then((res) => {
        if (res.data.errcode == 0) {
          // 弹出遮罩层
          state.show = true;
          sessionStorage.setItem("accessToken", res.data.data.accessToken);
        } else {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        }
      });
    };
    //关闭遮罩层
    const closeMask = () => {
      state.show = false;
    };
    // 协议指南
    const protocolPrivacy = () => {
      router.push({ path: "/agreement" });
    };
    const onClickLeft = () => {
      router.push({ path: "/register" });
    };
    onMounted(() => {
      getCodeTime();
    });

    return {
      ...toRefs(state),
      protocolPrivacy,
      getDone,
      getEmail,
      getCodeTime,
      closeMask,
      onClickLeft,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .goback {
    position: absolute;
    background-color: transparent;
    top: 6%;
    left: 7%;
    width: 35px;
    z-index: 1;
  }
  // 整体表单
  .loginBox {
    background-color: transparent;
    z-index: 1;
    position: absolute;
    width: 37%;
    height: 100%;
    overflow: hidden;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 5% 30px 13.8% 0px;
    color: #ffffff;
    // 快乐星球盒子
    .accountTitle {
      height: 12%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 40px;
        height: 100%;
      }
      .englishTitle {
        font-size: 10px;
      }
    }
    // SING IN
    .tableTitle {
      text-align: left;
      font-size: 20px;
    }
    // 表单背景色
    .cellTable {
      background-color: transparent !important;
      .van-cell {
        font-size: 10px;
        overflow: hidden;
        padding-left: 6px;
      }
      // 输入表单的背景色
      .van-field {
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 30px;
        line-height: 30px;
      }
    }
    .checktitle {
      width: 246px;
      height: 30px;
      color: grey;
      text-align: left;
      line-height: 12px;
      font-size: 10px;
      margin: 10px 0px;
    }
    // 获取验证码
    .getCode {
      display: block;
      background-color: transparent !important;
      color: #ffffff;
      border: none;
      border-bottom: 2px solid #ffffff;
      height: 14px;
      margin-bottom: 10px;
    }
    // 倒计时按钮
    .countDown {
      color: gray !important;
      border-bottom: 2px solid gray;
    }
    // 登录按钮
    .loginButton {
      width: 100%;
      height: 40px;
      background-color: transparent;
      background-image: url("../assets/img/account.png");
      background-size: 100%;
      background-repeat: no-repeat;
      border: none;
      margin: 14px 0px;
    }
  }
  // 遮罩层
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .closeButton {
      position: absolute;
      width: 6%;
      top: 5%;
      right: 5%;
    }
  }
}
</style>
